<template>
    <el-dialog
        width="980px"
        top="20px"
        :title="$t('employee_details.assign_key')"
        :visible.sync="modalAssignKeyVisible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-table :data="keysData" style="width: 100%">
            <el-table-column :label="$t('employee_details.address')">
                <template slot-scope="scope">
                    <span>{{ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('employee_details.client')" width="250">
                <template slot-scope="scope">
                    <router-link :to="`/clients/${scope.row.client_uuid}/dashboard`" class="hover:text-red-500 hover:font-semibold">
                        {{ scope.row.client }}
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column :label="$t('employee_details.holds')" width="200">
                <template slot-scope="scope">
                    <router-link
                        v-if="scope.row.holder_uuid"
                        :to="`/employees/${scope.row.holder_uuid}/information`"
                        class="hover:text-red-500 hover:font-semibold"
                    >
                        {{ currentHolderFormatter(scope.row.holder) }}
                    </router-link>
                    <p v-else>
                        {{ currentHolderFormatter(scope.row.holder) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column width="140">
                <template slot-scope="scope">
                    <el-button
                        v-if="!(selectedEmployeeUuid === scope.row.holder_uuid)"
                        type="primary"
                        :loading="$wait.is(`updating.${scope.row.uuid}`)"
                        @click="update(scope.row)"
                    >
                        {{ $t('employee_details.assign_key') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalAssignKeyVisible: {
            type:    Boolean,
            default: false,
        },
        selectedEmployeeUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            keysData:      [],
            employeesData: [],
            employeeName:  '',
        };
    },

    created() {
        this.getCurrentEmployee();
        this.getAll();
    },

    methods: {
        currentHolderFormatter(holder) {
            if (holder === 'office' || holder === 'client') {
                return this.$t(`employee_details.at_${holder}`);
            }
            return holder;
        },

        async getAll() {
            this.$wait.start('loading_keys');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys`);

            this.keysData = data;
            this.$wait.end('loading_keys');
        },

        async update(key) {
            this.$wait.start(`updating.${key.uuid}`);

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${key.uuid}`, {
                name:        key.name,
                description: key.description,
                holder:      this.employeeName,
                holder_uuid: this.selectedEmployeeUuid,
                image_src:   key.image_src,
            });

            this.$wait.end(`updating.${key.uuid}`);
            this.$notify.success({ title: this.$t('common.success') });
            this.getAll();
            this.$emit('refresh');
        },

        async getCurrentEmployee() {
            this.$wait.start('loading.employees');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.employeesData = data;
            const employeeNameLocal = this.employeesData.filter(employee => employee.uuid === this.selectedEmployeeUuid);

            this.employeeName = `${employeeNameLocal[0].name} ${employeeNameLocal[0].surname}`;

            this.$wait.end('loading.employees');
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
